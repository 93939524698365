<template>
  <div class="birthdayRight">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>活动标题：</span>
      <el-input v-model="formData.title" placeholder="请输入活动标题"></el-input>
      <span>活动状态：</span>
      <el-select v-model="formData.status" clearable @clear="onClear(1)" placeholder="请选择">
        <el-option v-for="item in typeList" :key="item.value" :label="item.lable" :value="item.value" />
      </el-select>
      <el-button type="primary" class="btn" @click="getTableList()">查询</el-button>

      <el-button type="primary" class="btn" @click="$router.push('/addActive/0')"
        v-if="$store.state.powerList.indexOf('activity:info:list:add') !== -1">
        添加
      </el-button>
    </div>

    <el-table border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="activityId" label="活动Id"> </el-table-column>
      <el-table-column prop="title" label="活动标题"> </el-table-column>
      <!-- <el-table-column prop="addTime" label="添加时间"> </el-table-column> -->
      <el-table-column prop="strStatus" label="状态"> </el-table-column>
      <el-table-column prop="activeTime" label="活动时间" width="320"> </el-table-column>
      <el-table-column prop="clickNo" label="访问人数">
      </el-table-column>
      <el-table-column prop="userNo" label="注册人数" >
      </el-table-column>
      <el-table-column prop="orderNo" label="下单数量">
      </el-table-column>
      <el-table-column label="操作" width="450">
        <template slot-scope="{ row }">
          <!-- <el-button
            size="mini"
            @click="onIsShow(row, 3)"
            type="primary"
            v-if="
              $store.state.powerList.indexOf('activity:info:list:delete') !== -1
            "
          >
            删除
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            v-if="
              $store.state.powerList.indexOf('activity:info:list:update') !==
                -1 && row.status === 1
            "
            @click="onIsShow(row, 2)"
          >
            隐藏
          </el-button>
          <el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            v-if="
              $store.state.powerList.indexOf('activity:info:list:update') !==
                -1 && row.status === 2
            "
            @click="onIsShow(row, 1)"
          >
            显示
          </el-button>
          <el-button
            type="primary"
            size="mini"
            v-if="
              $store.state.powerList.indexOf('activity:info:list:edit') !== -1
            "
            @click="$router.push('/addActive/' + row.activityId)"
          >
            编辑
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="onShowAddress(row.activityId)"
            >查看页面路径</el-button
          > -->
          <el-button type="primary" size="mini" @click="$router.push('/regionalManagement/' + row.activityId)">
            区域管理
          </el-button>
          <el-button type="primary" size="mini" @click="code(row)" style="margin-right: 20px">生成二维码</el-button>
          <el-dropdown>
            <el-button size="mini" type="primary">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" style="text-align: center;">
              <el-dropdown-item><span @click="onIsShow(row, 3)" v-if="$store.state.powerList.indexOf('activity:info:list:delete') !== -1
                ">
                  删除
                </span></el-dropdown-item>
              <el-dropdown-item><span v-if="$store.state.powerList.indexOf('activity:info:list:update') !==
                -1 && row.status === 1
                " @click="onIsShow(row, 2)">
                  隐藏
                </span></el-dropdown-item>
              <el-dropdown-item> <span v-if="$store.state.powerList.indexOf('activity:info:list:update') !==
                -1 && row.status === 2
                " @click="onIsShow(row, 1)">
                  显示
                </span></el-dropdown-item>
              <el-dropdown-item> <span v-if="$store.state.powerList.indexOf('activity:info:list:edit') !== -1
                " @click="$router.push('/addActive/' + row.activityId)">
                  编辑
                </span></el-dropdown-item>
              <el-dropdown-item><span @click="onShowAddress(row.activityId)">查看路径</span></el-dropdown-item>
              <el-dropdown-item> <span  @click="$router.push('/orderDetails/3?activityId=' + row.activityId)">
                  下单详情
                </span></el-dropdown-item>
                <el-dropdown-item> <span @click="govipList(row.activityId)">
                  注册详情
                </span></el-dropdown-item>
                <el-dropdown-item> <span v-if="$store.state.powerList.indexOf('activity:click:list:select') !== -1
                " @click="$router.push('/visitDetails/' + row.activityId +'/0')">
                  访问详情
                </span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn_box">
      <el-button type="primary" @click="onChangeAll(3)">批量删除</el-button>
      <el-button type="primary" @click="onChangeAll(1)">批量显示</el-button>
      <el-button type="primary" @click="onChangeAll(2)">批量隐藏</el-button>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="pagination.currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>
    <el-dialog title="查看活动页地址" :visible.sync="showAddress" width="35%">
      <div id="address">
        前台活动也地址:<el-input v-model="address" readonly id="wrapper"></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="copyinviteUrl">一键复制</el-button>
      </div>
      <div slot="footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="showAddress = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input style="width:300px" v-model.trim="QRcodeSize" placeholder="请输入生成的二维码大小"></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color:red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { activeList, changeStatus, delActive, regListCode } from "../../../api/activePage";
export default {
  name: "activeList",

  data() {
    return {
      showAddress: false,
      codeVisible: false,
      address: "",
      QRcodeSize: '',
      typeList: [
        { value: 1, lable: "显示" },
        { value: 2, lable: "隐藏" },
      ],

      formData: {
        status: null,
        title: "",
        pageSize: 10,
        currentPage: 1,
      },
      imgUrl: '',
      multipleSelection: [],
      tableData: [],
      pagination: {},
      activityId: '',
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    code(row) {
      this.codeVisible = true
      this.imgUrl = ''
      this.activityId = row.activityId
    },
    onShowAddress(id) {
      this.address = "components/navPages/navPages?activityId=" + id;
      this.showAddress = true;
    },
    copyinviteUrl() {
      this.$nextTick(() => {
        var inputElement = document.getElementById("wrapper");
        //选中input框的内容
        inputElement.select();
        // 执行浏览器复制命令
        document.execCommand("Copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },
    async generateQRcode() {
      this.imgUrl = ''
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await regListCode({
          activityId: this.activityId,
          widthSize: this.QRcodeSize
        })
        this.imgUrl = data.msg
        console.log(this.imgUrl);
      }

    },
    onChangeAll(type) {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning("请选择要添加的数据");
      }
      if (type === 1 || type === 2) {
        this.$confirm("是否操作这条数据", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await changeStatus({
              status: type,
              ids: this.multipleSelection.join(","),
            });
            if (data.code === 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getTableList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (type === 3) {
        this.$confirm("是否操作这条数据", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await delActive({
              ids: this.multipleSelection.join(","),
            });
            if (data.code === 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getTableList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    async getTableList() {
      const { data } = await activeList(this.formData);
      this.tableData = data.list;
      // this.tableData.activeTime = this.tableData.beginTime+'--'+this.tableData.endTime;
      this.tableData.forEach(item => {
        item.activeTime = item.beginTime + '--' + item.endTime;
      })
      this.pagination = data.pagination;
    },

    onIsShow(row, type) {
      if (type === 1 || type === 2) {
        this.$confirm("是否操作这条数据", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await changeStatus({
              status: type,
              ids: row.activityId + "",
            });
            if (data.code === 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getTableList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (type === 3) {
        this.$confirm("是否操作这条数据", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await delActive({
              ids: row.activityId + "",
            });
            if (data.code === 0) {
              this.$message({
                type: "success",
                message: data.msg,
              });
              this.getTableList();
            } else {
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    async toEdit() {
      for (var k in this.editForm) {
        if (this.editForm[k] == "")
          return this.$message.error("请填写完整信息!");
      }
      const { data } = await birthdayProductEdit(this.editForm);
      if (data.code == 0) {
        this.dialogVisible3 = false;
        this.getTableList();
        return this.$message.success(data.msg);
      }
      this.$message.error(data.msg);
    },
    hideVisible() {
      this.dialogVisible2 = false;
    },
    handleSelectionChange(arr) {
      var data = [];
      arr.forEach((item) => {
        data.push(item.activityId);
      });
      this.multipleSelection = data;
      console.log(this.multipleSelection);
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
    onInput(value) {
      var reg = /^(0\.?\d{0,5}|[1-9]\d*\.?\d{0,5})$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正确的数字!",
        });
      }
    },
    govipList(id){
      this.$router.push({name:'vipList',query:{activityId:id}})
    }
  },
};
</script>

<style lang="less" scoped>
.birthdayRight {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }

  .btn_box {
    float: left;
    margin-top: 50px;
  }

  .add {
    margin-top: 20px;
  }

  .el-table {
    margin-top: 50px;
  }

  .search_box {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 200px;
    }

    .btn {
      margin-left: 20px;
    }
  }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }

  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }

  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }

  .tips {
    // text-align: right;
    color: orangered;
  }

  .grade_list {
    padding: 0 10px 10px;

    .grade_item {
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 20px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      .grade_name {
        font-weight: bold;
        margin: 0px 0 10px;
      }

      .form_item {
        display: flex;

        .el-form-item {
          flex: 1;

          .el-input-number {
            width: 100%;
          }
        }
      }
    }
  }

  .btn_box {
    margin-top: 24px;
  }

  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  /deep/.popconfirm {
    margin-right: 10px;
  }

  #address {
    display: flex;
    align-items: center;

    .el-input {
      width: 350px;
    }
  }
}
</style>